<template>
  <b-overlay :show="showOverlay">
     <b-row>
      <b-col>
        <h2>{{boat.label}}</h2>
        <b-card-group deck>
          <b-card no-body header="Identité">
            <b-list-group flush>
              <b-list-group-item href="#">Embarcation : {{boat.type}}</b-list-group-item>
              <b-list-group-item href="#">Catégorie d'âge : {{category}}</b-list-group-item>
              <b-list-group-item href="#">
                Année de naissance : {{boat.slalom.year}}
              </b-list-group-item>
              <b-list-group-item href="#">
                Club : {{rankingCovid.clubs.map(club=>titleCase(club.clubName)).join(' / ')}}
              </b-list-group-item>
              <b-list-group-item href="#"></b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card no-body header="Classement national">
            <b-list-group flush>
              <b-list-group-item href="#">Divison : {{boat.slalom.level}}</b-list-group-item>
              <b-list-group-item href="#">Moyenne : {{ranking.value}}</b-list-group-item>
              <b-list-group-item href="#">
                Nombre de courses : {{ranking.raceCount}}
              </b-list-group-item>
              <b-list-group-item href="#">
                Nombre de courses nationales : {{ranking.nationalRaceCount}}
              </b-list-group-item>
              <b-list-group-item href="#"></b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card no-body header="Classement national modifié (COVID-19)">
            <b-list-group flush>
              <b-list-group-item href="#">Divison : {{boat.slalom.level}}</b-list-group-item>
              <b-list-group-item href="#">Moyenne : {{rankingCovid.value}}</b-list-group-item>
              <b-list-group-item href="#">
                Nombre de courses : {{rankingCovid.raceCount}}
              </b-list-group-item>
              <b-list-group-item href="#">
                Nombre de courses nationales : {{rankingCovid.nationalRaceCount}}
              </b-list-group-item>
              <b-list-group-item href="#"></b-list-group-item>
            </b-list-group>
          </b-card>
          <b-card no-body header="Statistiques">
            <b-list-group flush>
              <b-list-group-item href="#">Nombre de manches : {{results.length}}</b-list-group-item>
              <b-list-group-item href="#"></b-list-group-item>
            </b-list-group>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
    <hr>
    <b-row v-for="season in seasons" :key="season.year">
      <b-col>
        <h3>{{season.year}}</h3>
        <b-table
          striped borderless small
          :items="season.results"
          :fields="fields"
        >
          <template #head(details)="">
            <b-icon-plus-circle
              scale="0.80"
              @click="toggleDetailsAllRows(season.results)"
            />
          </template>
          <template v-slot:cell(details)="row">
            <b-icon-plus-circle scale="0.80" @click="row.toggleDetails"/>
          </template>
          <template v-slot:row-details="row">
            <b-card class="card-small-padding">
              <runs-details :runs="row.item.runs">
              </runs-details>
            </b-card>
          </template>
          <template v-slot:cell(raceDate)="data">
            {{ $DateTime.fromISO(data.item.raceDate).toISODate()}}
          </template>
           <template v-slot:cell(level)="data">
            {{getDivision(data.item.eventLevel)}}
          </template>
          <template v-slot:cell(name)="data">
            <router-link :to="'/race/'+data.item.raceId">
              {{ getRaceName(data.item.eventName, data.item.eventPlace, data.item.raceName) }}
            </router-link>
          </template>
          <template v-slot:cell(points)="data">
            <span :class="{
                red:data.item.points > data.item.value,
                green:data.item.points < data.item.value
              }"
            >
              {{data.item.points > -1 ? data.item.points : '-'}}
              </span>
          </template>
          <template v-slot:cell(value)="data">
            {{data.item.value > -1 ? data.item.value : '-'}}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { getDivision, getRaceName } from '../lib/race';
import titleCase from '../lib/string';
import RunsDetails from '../components/RunsDetails.vue';

export default {
  name: 'Boat',
  components: {
    RunsDetails,
  },
  data() {
    return {
      showOverlay: false,
      boat: { slalom: {} },
      category: '',
      results: [],
      seasons: [],
      ranking: {
        raceCount: null, nationalRaceCount: null, value: null, clubs: [],
      },
      rankingCovid: {
        raceCount: null, nationalRaceCount: null, value: null, clubs: [],
      },
      fields: [
        { key: 'details', label: '' },
        { key: 'raceDate', label: 'Date' },
        { key: 'level', label: 'Niveau' },
        { key: 'name', label: 'Intitulé' },
        { key: 'rank', label: 'Rang' },
        { key: 'boatType', label: 'Catégorie' },
        { key: 'points', label: 'Points' },
        { key: 'value', label: 'Valeur' },
      ],
    };
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getDivision,
    getRaceName,
    titleCase,
    async getData() {
      this.showOverlay = true;
      try {
        await Promise.all([
          this.getBoat(), this.getResults(), this.getRanking(), this.getRankingCovid(),
        ]);
        this.showOverlay = false;
      } catch (error) {
        this.showOverlay = false;
      }
    },
    async getBoat() {
      const result = await this.axios.get(`/ffck/boats/${this.$route.params.boatId}`);
      if (result && result.data) {
        this.boat = result.data;
      }
    },
    async getResults() {
      const result = await this.axios.get(`/ffck/race/phase/results?boatId=${this.$route.params.boatId}`);
      if (result && result.data) {
        this.seasons = [];
        this.results = result.data;
        if (Array.isArray(result.data)) {
          const seasons = {};
          result.data.forEach((raceResult) => {
            const date = this.$DateTime.fromISO(raceResult.raceDate);
            if (!seasons[date.year]) {
              seasons[date.year] = [];
            }
            seasons[date.year].push({ ...raceResult, date, _showDetails: false });
          });
          Object.keys(seasons).forEach((key) => {
            this.seasons.push({
              year: key,
              results: seasons[key],
            });
          });
        }
      }
      this.seasons.sort((a, b) => b.year - a.year);
    },
    async getRanking() {
      const result = await this.axios.get(`/ffck/ranking/national2021/boat/${this.$route.params.boatId}`);
      if (result && result.data) {
        this.category = result.data.category || this.boat.category;
        const {
          raceCount, extras: { nationalRacesCount: nationalRaceCount }, value, clubs,
        } = result.data;
        this.ranking = {
          raceCount,
          nationalRaceCount,
          value,
          clubs,
        };
      }
    },
    async getRankingCovid() {
      const result = await this.axios.get(`/ffck/ranking/rankingCovid2021/boat/${this.$route.params.boatId}`);
      if (result && result.data) {
        this.category = result.data.category || this.boat.category;
        const {
          raceCount, extras: { nationalRacesCount: nationalRaceCount }, value, clubs,
        } = result.data;
        this.rankingCovid = {
          raceCount,
          nationalRaceCount,
          value,
          clubs,
        };
      }
    },
    toggleDetailsAllRows(rows) {
      rows.forEach((row) => {
        // eslint-disable-next-line no-underscore-dangle, no-param-reassign
        row._showDetails = !row._showDetails;
      });
    },
  },
};
</script>

<style scoped>
  .card-header {
    font-weight: bold;
    font-size: 120%;
  }
  .card-small-padding div {
    padding: 0.25rem;
  }
</style>
